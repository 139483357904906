import React from "react"
import ReactDOM from "react-dom/client"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"

import { DocusignRedirectPage } from "./pages/DocusignRedirectPage"
import { IframeTesterPage } from "./pages/IframeTesterPage"

import App from "./App"

import "./index.css"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/iframeTester" element={<IframeTesterPage />} />
        <Route path="/docusign-redirect" element={<DocusignRedirectPage />} />
      </Routes>
    </Router>
  </React.StrictMode>
)
